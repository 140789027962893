import { combineReducers } from 'redux';
import articleList from 'admin/src/redux/reducers/articleList';
import article from 'admin/src/redux/reducers/article';
import level from 'admin/src/redux/reducers/level'
import subject from 'admin/src/redux/reducers/subject';
import category from 'admin/src/redux/reducers/category';
import relatedArticleList from './redux/relatedArticlesReducer';
import curriculumByIds from './redux/curriculumByIdsReducer';

export default combineReducers({
    articleList,
    relatedArticleList,
    article,
    level,
    subject,
    category,
    curriculumByIds
});
