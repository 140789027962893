import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';

import config from 'common/config';
import { requestRelatedArticles } from '../redux/articleAction';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 2880,
        // minHeight: 500,
        backgroundColor: '#E9EFF2',
        marginLeft: 'auto',
        marginRight: 'auto',
       /* marginTop: 80,*/
        '& .gridContainer': {
            justifyContent: 'center',
            '& .gridItem': {
                marginTop: theme.spacing(5),
                marginBottom: theme.spacing(5),
                [theme.breakpoints.down('xs')]: {
                    marginTop: 0,
                },
                '& div': {
                    marginTop: 19
                },
                '& a': {
                    color: '#115E86',
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline'
                    }
                }
            }
        },
        '& h2': {
            fontSize: 24,
            [theme.breakpoints.down('xs')]: {
                fontSize: 16
            }
        }
    }
}));

export const RelatedResources = ({ pageId, section }) => {
    const dispatch = useDispatch(),
        classes = useStyles(),
        blocks = (section && section.blocks) || [],
        artBlock = blocks.find(b => (b.type === 'related__resources')),
        asmBlock = blocks.find(b => (b.type === 'related__assignments')),
        conBlock = blocks.find(b => (b.type === 'related__contributions')),
        articles = artBlock && artBlock.resources.relatedList.filter(a => !!a.dimu_unique_id),
        assignments = asmBlock && asmBlock.resources.relatedList.filter(a => !!a.dimu_unique_id),
        contributions = conBlock && conBlock.resources.relatedList.filter(a => !!a.dimu_unique_id),
        { relatedArticles, relatedAssignments, relatedContributions } = useSelector(state => state.relatedArticleList);

    useEffect(() => {
        if (pageId) {
            dispatch(requestRelatedArticles(pageId));
        }
    }, [dispatch, pageId]);

    if (!(articles && articles.length) &&
        !(assignments && assignments.length) &&
        !(contributions && contributions.length) &&
        !(relatedArticles && relatedArticles.length) &&
        !(relatedAssignments && relatedAssignments.length) &&
        !(relatedContributions && relatedContributions.length)) {
        return null;
    }

    const renderRelatedLink = rel => {
        const portalUrl = config.get('portalUrl');

        return (
            <div key={rel.title}>
                <a href={portalUrl
                        ? `${portalUrl}/${rel.dimu_unique_id}/`
                        : `/${rel.dimu_unique_id}/`
                    }
                    target="_blank" rel="noreferrer">{rel.title}</a>
            </div>
        );
    };

    const renderBackRef = (rel, exists) => {
        const duplicate = exists && exists.filter(x => x.dimu_unique_id === rel.dimu_unique_id);

        if (duplicate && duplicate.length !== 0) {
            return null;
        }

        return renderRelatedLink(rel);
    };

    return (
        <Container className={classes.container}>
            <Grid container spacing={3} className="gridContainer">
                {((articles && articles.length) || relatedArticles)
                    ? (
                        <Grid item xs={12} sm={6} md={4} lg={3} className="gridItem">
                            <h2>Relaterte Ressurser</h2>
                            {articles && articles.map(renderRelatedLink)}
                            {relatedArticles && relatedArticles.map(item => renderBackRef(item, articles))}
                        </Grid>
                    ) : null
                }
                {((assignments && assignments.length) || relatedAssignments)
                    ? (
                        <Grid item xs={12} sm={6} md={4} lg={3} className="gridItem">
                            <h2>Relaterte Oppgaver</h2>
                            {assignments && assignments.map(renderRelatedLink)}
                            {relatedAssignments && relatedAssignments.map(item => renderBackRef(item, assignments))}
                        </Grid>
                    ) : null
                }
                {((contributions && contributions.length) || relatedContributions)
                    ? (
                        <Grid item xs={12} sm={6} md={4} lg={3} className="gridItem">
                            <h2>Relaterte Innsendte Bidrag</h2>
                            {contributions && contributions.map(renderRelatedLink)}
                            {relatedContributions && relatedContributions.map(item => renderBackRef(item, contributions))}
                        </Grid>
                    ) : null
                }
            </Grid>
        </Container>
    );
};
