import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import { MinstemmmePortal } from "./Home";
import { ArticleView } from "./ArticleView";
import ScrollToTop from "./scrollToTop";

export const App = () => {
    ReactGA.initialize(
        localStorage.getItem('ga-test')
            ? "G-PTQPL6623C"
            : "G-5YTPZGNM2H"
    );

    return (
        <Router>
            <ScrollToTop />
            <Route exact path="/" component={MinstemmmePortal} />
            <Route exact path="/:pageId/:slug" component={ArticleView} />
            <Route exact path="/:pageId" component={ArticleView} />
        </Router>
    );
};
