import config from 'common/config';

export const ARTICLES_APPENDED = 'ARTICLE_APPENDED',
    RELATED_ARTICLES_REQUESTED = 'RELATED_ARTICLES_REQUESTED',
    RELATED_ARTICLES_SUCCEEDED = 'RELATED_ARTICLES_SUCCEEDED',
    RELATED_ARTICLES_FAILED = 'RELATED_ARTICLES_FAILED';


export const requestArticles = (params, offset = 0) => dispatch => {
    dispatch({
        type: offset ? 'ARTICLES_APPEND_REQUESTED' : 'ARTICLES_REQUESTED'
    });

    fetch(`${config.get('publicApi')}minstemme/articles/${params}${params ? '&' : '?'}offset=${offset}`)
        .then(resp => {
            return resp.json();
        })
        .then(data => dispatch({
            type: data.offset ? ARTICLES_APPENDED : 'ARTICLES_SUCCEEDED',
            data: data
        })
        )
        .catch(error => dispatch({
            type: 'ARTICLES_FAILED',
            data: error
        }));
};

/* export const requestSSArticle = id => {
    const headers = new Headers({
        // 'Content-Type': 'application/json',
        // 'accept-language': 'en-US,en;q=0.9,nb;q=0.8,sv;q=0.7,und;q=0.6,no;q=0.5',
        // 'cache-control': 'no-cache'
        'Content-Type':'text/plain; charset=UTF-8'
    });

    const url = id === 'om-nettstedet'
        // ? `${config.get('publicApi')}minstemme/article/static/860/`
        ? `${config.get('publicApi')}minstemme/article/static/872/`
        : `${config.get('publicApi')}minstemme/article/${id}/`

    fetch(url, { headers })
        .then(resp => {
            return resp.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            console.log(`ARTICLE_FAILED: ${id}. Error: ${error}`);
        });
}; */

export const requestArticle = (id, slug) => dispatch => {
    dispatch({
        type: 'ARTICLE_REQUESTED'
    });

    const headers = new Headers({
        // 'Content-Type': 'application/json',
        // 'accept-language': 'en-US,en;q=0.9,nb;q=0.8,sv;q=0.7,und;q=0.6,no;q=0.5',
        // 'cache-control': 'no-cache'
        'Content-Type':'text/plain; charset=UTF-8'
    });

    const url = id === 'om-nettstedet'
        // ? `${config.get('publicApi')}minstemme/article/static/860/`
        ? `${config.get('publicApi')}minstemme/article/static/872/`
        : `${config.get('publicApi')}minstemme/article/${id}/${slug ? slug + '/' : ''}`

    fetch(url, { headers })
        .then(resp => {
            return resp.json();
        })
        .then(json => {
            dispatch({
                type: 'ARTICLE_SUCCEEDED',
                data: json
            })
        })
        .catch(error => dispatch({
            type: 'ARTICLE_FAILED',
            data: error,
            pageId: id
        }));
};

export const requestRelatedArticles = id => dispatch => {
    dispatch({
        type: 'RELATED_ARTICLES_REQUESTED'
    });

    return fetch(`${config.get('publicApi')}minstemme/related/${id}/`)
        .then(resp => {
            return resp.json();
        })
        .then(json => {
            dispatch({
                type: 'RELATED_ARTICLES_SUCCEEDED',
                data: json
            })
        })
        .catch(error => dispatch({
            type: 'RELATED_ARTICLES_FAILED',
            data: error,
            pageId: id
        }));
};
