import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ReactGA from "react-ga";

import { Main } from "./content/Main";
import { requestArticle } from "./redux/articleAction";

export const ArticleView = () => {
    const dispatch = useDispatch(),
        { pageId, slug } = useParams(),
        { data, fetching } = useSelector(state => state.article);

    useEffect(() => {
        if (pageId) {
            dispatch(requestArticle(pageId, slug));
        }
    }, [dispatch, pageId]);

    /* useEffect(() => {
        // if (window._env_.REACT_APP_ENV === 'portal') {
        if (document.location.hostname !== 'localhost') {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, []); */

    if (data && data.redirect) {
        if (data.redirect !== pageId) {
            return (
                <Redirect
                    to={{
                        pathname: `/${data.redirect}`,
                        state: { status: 301 },
                    }}
                />
            );
        }

        return null;
    }

    return (
        <>
            {data && <Main data={data} fetching={fetching} anchorObj={false} />}
        </>
    );
};
