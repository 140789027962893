import React from 'react';
import { Trans } from 'react-i18next';


export const License = ({ code }) => {
    if (code === 'copyright') {
        return (
            <span className="media__credit license">
                <Trans ns="dm.article" i18nKey="license_COPYRIGHT">Rettighetsbelagt (Opphavsrett)</Trans>
            </span>
        )
    }

    let url = 'http://creativecommons.org/',
        img = '//licensebuttons.net/';

    // return <span>{license}</span>
    if (code === 'CC pdm') {
        url += 'publicdomain/mark/1.0/deed';
        img += 'p/mark/1.0/80x15.png';
    } else if (['CC CC0', 'CC CC0 1.0'].includes(code)){
        url += 'publicdomain/zero/1.0/deed';
        img += 'p/zero/1.0/80x15.png';
    } else {
        url += `licenses/${code.replace('CC ', '')}/4.0/deed`;
        img += `l/${code.replace('CC ', '')}/4.0/80x15.png`;
    }

    return (
        <a className="license" href={url} target="_blank" rel="noreferrer">
            <img src={img} alt={code} />
        </a>
    )
};
