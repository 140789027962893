// import { kitFetchExternal } from '@kulturit/header-state';
import { kitFetch } from '@ekultur/header-state';
import config from 'common/config';
// import { setParams } from 'common/search/searchParams';


export const ARTICLE_REQUESTED = 'ARTICLE_REQUESTED';
export const ARTICLE_SUCCEEDED = 'ARTICLE_SUCCEEDED';
export const ARTICLES_REQUESTED = 'ARTICLES_REQUESTED';
export const ARTICLES_SUCCEEDED = 'ARTICLES_SUCCEEDED';
export const ARTICLE_UPDATE_REQUESTED = 'ARTICLE_UPDATE_REQUESTED';
export const ARTICLE_UPDATED = 'ARTICLE_UPDATED';
export const ARTICLE_AFTER_UPDATE = 'ARTICLE_AFTER_UPDATE';
export const ARTICLE_FAILED = 'ARTICLE_FAILED';
export const ARTICLES_FAILED = 'ARTICLES_FAILED';
export const ARTICLE_DELETE_REQUESTED = 'ARTICLE_DELETE_REQUESTED';
export const ARTICLE_DELETED = 'ARTICLE_DELETED';


export const requestArticles = params => dispatch => {
    dispatch({
        type: ARTICLES_REQUESTED
    });

    // kitFetchExternal(`${config.get('articleApi')}page/all/${ownerId}/?userId=${userId}`)
    kitFetch(`${config.get('articleApi')}minstemme/pages/${params}`)
        .then(data => dispatch({
            type: ARTICLES_SUCCEEDED,
            data: data
        }))
        .catch(error => dispatch({
            type: ARTICLES_FAILED,
            data: error
        }));
};

export const requestArticle = pageId => dispatch => {
    dispatch({
        type: ARTICLE_REQUESTED
    });

    // kitFetchExternal(`${config.get('articleApi')}page/${pageId}/?owner=${ownerId}&userId=${userId}`)
    kitFetch(`${config.get('articleApi')}minstemme/page/${pageId}/`)
        .then(page => dispatch({
            type: ARTICLE_SUCCEEDED,
            data: page
        }))
        .catch(error => dispatch({
            type: ARTICLE_FAILED,
            data: error,
            pageId: pageId
        }));
};

export const updateArticle = (user, data) => dispatch => {
    dispatch({
        type: ARTICLE_UPDATE_REQUESTED
    });

    const postConfig = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    // kitFetchExternal(`${config.get('articleApi')}page/update/?owner=${owner}&userId=${user}`, postConfig)
    kitFetch(`${config.get('articleApi')}minstemme/page/update/`, postConfig)
        .then(page => dispatch({
            type: page['error-id'] ? ARTICLE_FAILED : ARTICLE_UPDATED,
            data: page['error-id'] ? page.message : page
        }))
        .catch(error => dispatch({
            type: ARTICLE_FAILED,
            data: error
        }));
};

export const requestNewArticle = pageType => dispatch => {
    dispatch({
        type: ARTICLE_REQUESTED
    });

    kitFetch(`${config.get('articleApi')}minstemme/page/new/${pageType}/`)
        .then(page => dispatch({
            type: page['error-id'] ? ARTICLE_FAILED : ARTICLE_SUCCEEDED,
            data: page['error-id'] ? page.message : page,
            created: true
        }))
        .catch(error => dispatch({
            type: ARTICLE_FAILED,
            data: error
        }));
};

export const deleteArticle = pageId => dispatch => {
    dispatch({
        type: ARTICLE_DELETE_REQUESTED
    });

    const postConfig = { method: 'DELETE' };

    kitFetch(`${config.get('articleApi')}minstemme/page/${pageId}/`, postConfig)
        .then(page => dispatch({
            type: page['error-id'] ? ARTICLE_FAILED : ARTICLE_DELETED,
            data: page['error-id'] ? page.message : pageId
        }))
        .catch(error => dispatch({
            type: ARTICLE_FAILED,
            data: error
        }));
};
