import { kitFetch } from '@ekultur/header-state';
import config from 'common/config';

export const CATEGORY_REQUESTED = 'CATEGORY_REQUESTED',
    CATEGORY_SUCCEEDED = 'CATEGORY_SUCCEEDED',
    CATEGORY_UPDATED = 'CATEGORY_UPDATED',
    CATEGORY_FAILED = 'CATEGORY_FAILED';

export const CURRICULUM_REQUESTED = 'CURRICULUM_REQUESTED',
    CURRICULUM_SUCCEEDED = 'CURRICULUM_SUCCEEDED',
    CURRICULUM_FAILED = 'CURRICULUM_FAILED',
    CURRICULUM_DELETE_REQUESTED = 'CURRICULUM_DELETE_REQUESTED',
    CURRICULUM_BY_IDS_REQUESTED = 'CURRICULUM_BY_IDS_REQUESTED',
    CURRICULUM_BY_IDS_SUCCEEDED = 'CURRICULUM_BY_IDS_SUCCEEDED',
    CURRICULUM_BY_IDS_FAILED = 'CURRICULUM_BY_IDS_FAILED';

export const CURRICULUM_UPDATE_REQUESTED = 'CURRICULUM_UPDATE_REQUESTED',
    CURRICULUM_UPDATED = 'CURRICULUM_UPDATED',
    CURRICULUM_UPDATE_FAILED = 'CURRICULUM_UPDATE_FAILED';

export const LEVEL_REQUESTED = 'LEVEL_REQUESTED',
    LEVEL_SUCCEEDED = 'LEVEL_SUCCEEDED',
    LEVEL_UPDATED = 'LEVEL_UPDATED',
    LEVEL_DELETED = 'LEVEL_DELETED',
    LEVEL_FAILED = 'LEVEL_FAILED';

export const SUBJECT_REQUESTED = 'SUBJECT_REQUESTED',
    SUBJECT_SUCCEEDED = 'SUBJECT_SUCCEEDED',
    SUBJECT_UPDATED = 'SUBJECT_UPDATED',
    SUBJECT_DELETED = 'SUBJECT_DELETED',
    SUBJECT_FAILED = 'SUBJECT_FAILED';


export const requestFilter = type => dispatch => {
    dispatch({
        type: `${type.toUpperCase()}_REQUESTED`
    });

    fetch(`${config.get('publicApi')}minstemme/resources/${type}/`)
        .then(resp => {
            return resp.json();
        })
        .then(data => dispatch({
            type: `${type.toUpperCase()}_SUCCEEDED`,
            data: data
        })
        )
        .catch(error => dispatch({
            type: `${type.toUpperCase()}_FAILED`,
            data: error
        }));
};

export const requestCurriculums = ids => dispatch => {
    dispatch({
        type: CURRICULUM_BY_IDS_REQUESTED
    });

    fetch(`${config.get('publicApi')}minstemme/resources/curriculum/${ids}/`)
        .then(resp => {
            return resp.json();
        })
        .then(data => dispatch({
            type: CURRICULUM_BY_IDS_SUCCEEDED,
            data: data
        })
        )
        .catch(error => dispatch({
            type: CURRICULUM_BY_IDS_FAILED,
            data: error
        }));
};

export const addResource = (user, type, resource) => dispatch => {
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({
            title: resource.title,
            parentId: resource.source,
            userId: user.uniqueId
        })
    };

    kitFetch(`${config.get('articleApi')}minstemme/${type}/add/`, postConfig)
        .then(res => dispatch({
            type: `${type.toUpperCase()}_UPDATED`,
            data: res,
            status: 'added'

        }))
        .catch(error => dispatch({
            type: `${type.toUpperCase()}_FAILED`,
            data: error
        }));
};

export const updateResource = (user, type, resource) => dispatch => {
    let ids = { userId: user.uniqueId, resourceId: resource.id, parentId: resource.source };
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({title: resource.title, ...ids })
    };

    kitFetch(`${config.get('articleApi')}minstemme/${type}/update/`, postConfig)
        .then(res => dispatch({
            type: `${type.toUpperCase()}_UPDATED`,
            data: res

        }))
        .catch(error => dispatch({
            type: `${type.toUpperCase()}_FAILED`,
            data: error
        }));
};

export const deleteResource = (type, resourceId) => dispatch => {
    // dispatch({
    //     type: CURRICULUM_DELETE_REQUESTED
    // });

    kitFetch(`${config.get('articleApi')}minstemme/resources/${resourceId}/`, { method: 'DELETE' })
        .then(res => dispatch({
            type: res['error-id'] ? `${type.toUpperCase()}_DELETE_FAILED` : `${type.toUpperCase()}_UPDATED`,
            data: res['error-id'] ? res.message : { id: resourceId },
            status: res['error-id'] ? '' : 'deleted'
        }))
        .catch(error => dispatch({
            type: `${type.toUpperCase()}_DELETE_FAILED`,
            data: error
        }));
};

export const addCurriculum = (user, sbjId, lvlId, resource) => dispatch => {
    dispatch({
        type: CURRICULUM_UPDATE_REQUESTED
    });

    const postConfig = {
        method: 'POST',
        body: JSON.stringify({
            description: resource.description,
            userId: user.uniqueId,
            subject: sbjId,
            level: lvlId
        })
    };

    kitFetch(`${config.get('articleApi')}minstemme/curriculum/add/`, postConfig)
        .then(media => dispatch({
            type: CURRICULUM_UPDATED,
            data: media,
            subject: sbjId,
            level: lvlId,
            status: 'added'

        }))
        .catch(error => dispatch({
            type: CURRICULUM_UPDATE_FAILED,
            data: error
        }));
};

export const updateCurriculum = (user, sbjId, lvlId, resource) => dispatch => {
    dispatch({
        type: CURRICULUM_UPDATE_REQUESTED
    });

    let ids = {userId: user.uniqueId, resourceId: resource.id}
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({description: resource.description, ...ids })
    };

    kitFetch(`${config.get('articleApi')}minstemme/curriculum/update/`, postConfig)
        .then(media => dispatch({
            type: CURRICULUM_UPDATED,
            data: media,
            subject: sbjId,
            level: lvlId

        }))
        .catch(error => dispatch({
            type: CURRICULUM_UPDATE_FAILED,
            data: error
        }));
};

export const deleteCurriculum = (sbjId, lvlId, resourceId) => dispatch => {
    dispatch({
        type: CURRICULUM_DELETE_REQUESTED
    });

    kitFetch(`${config.get('articleApi')}minstemme/resources/${resourceId}/`, { method: 'DELETE' })
        .then(res => dispatch({
            type: res['error-id'] ? CURRICULUM_FAILED : CURRICULUM_UPDATED,
            data: res['error-id'] ? res.message : { id: resourceId },
            subject: sbjId,
            level: lvlId,
            status: res['error-id'] ? '' : 'deleted'
        }))
        .catch(error => dispatch({
            type: CURRICULUM_FAILED,
            data: error
        }));
};
