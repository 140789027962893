import { kitFetch, kitFileUpload } from '@ekultur/header-state';

import config from 'common/config';

export const RESOURCES_REQUESTED = 'RESOURCES_REQUESTED';
export const RESOURCES_SUCCEEDED = 'RESOURCES_SUCCEEDED';
export const RESOURCES_FAILED = 'RESOURCES_FAILED';
export const RESOURCE_DELETE_REQUESTED = 'RESOURCE_DELETE_REQUESTED';
export const RESOURCE_DELETED = 'RESOURCE_DELETED';

export const SEARCH_REQUESTED = 'SEARCH_REQUESTED';
export const SEARCH_SUCCEEDED = 'SEARCH_SUCCEEDED';
export const SEARCH_FAILED = 'SEARCH_FAILED';

export const PREVIEW_SEARCH_REQUESTED = 'PREVIEW_SEARCH_REQUESTED';
export const PREVIEW_SEARCH_SUCCEEDED = 'PREVIEW_SEARCH_SUCCEEDED';
export const PREVIEW_SEARCH_FAILED = 'PREVIEW_SEARCH_FAILED';

export const MEDIA_UPLOAD_REQUESTED = 'MEDIA_UPLOAD_REQUESTED';
export const MEDIA_UPLOAD_SUCCEEDED = 'MEDIA_UPLOAD_SUCCEEDED';
export const MEDIA_UPLOAD_FAILED = 'MEDIA_UPLOAD_FAILED';

export const VIDEO_UPLOAD_REQUESTED = 'VIDEO_UPLOAD_REQUESTED';
export const VIDEO_UPLOAD_SUCCEEDED = 'VIDEO_UPLOAD_SUCCEEDED';
export const VIDEO_UPLOAD_FAILED = 'VIDEO_UPLOAD_FAILED';
export const VIDEO_UPLOAD_STATUS = 'VIDEO_UPLOAD_STATUS';

export const IMAGE_DATA_UPDATE_REQUESTED = 'IMAGE_DATA_UPDATE_REQUESTED';
export const IMAGE_DATA_UPDATED = 'IMAGE_DATA_UPDATED';
export const IMAGE_DATA_UPDATE_FAILED = 'IMAGE_DATA_UPDATE_FAILED';

export const requestResources = (owner, type = 'media', q = null, rows = 25, start = 0) => dispatch => {
    dispatch({
        type: RESOURCES_REQUESTED
    });

    const qs = `?rows=${rows}&start=${start}${q ? ('&q=' + q) : ''}`;

    kitFetch(`${config.get('articleApi')}minstemme/resources/${type}/${qs}`)
        .then(data => dispatch({
            type: RESOURCES_SUCCEEDED,
            data: data
        }))
        .catch(error => dispatch({
            type: RESOURCES_FAILED,
            data: error
        }));
};

export const updateResources = (user, data, resource) => dispatch => {
    dispatch({
        type: IMAGE_DATA_UPDATE_REQUESTED
    });

    let ids = {userId: user.uniqueId, resourceId: resource.id}
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({...data, ...ids })
    };

    kitFetch(`${config.get('articleApi')}minstemme/resources/update/`, postConfig)
        .then(media => dispatch({
            type: IMAGE_DATA_UPDATED,
            data: media

        }))
        .catch(error => dispatch({
            type: IMAGE_DATA_UPDATE_FAILED,
            data: error
        }));
};

export const requestDmSearch = (query, resetResult = true, preview = false) => dispatch => {
    dispatch({
        type: preview ? PREVIEW_SEARCH_REQUESTED : SEARCH_REQUESTED,
        query: query
    });

    kitFetch(`${config.get('articleApi')}minstemme/search?q=${query}`)
        .then(data => dispatch({
            type: preview ? PREVIEW_SEARCH_SUCCEEDED : SEARCH_SUCCEEDED,
            data: data,
            resetResult: resetResult,
            query: query
        }))
        .catch(error => dispatch({
            type: preview ? PREVIEW_SEARCH_FAILED : SEARCH_FAILED,
            data: error,
            query: query
        }));
};

export const uploadMedia = (uploadData, userId) => dispatch => {
    const data = new FormData(),
        file = uploadData[0];

    data.append('file', file, file && file.name);
    data.append('mime', file && file.type);
    data.append('userId', userId);

    dispatch({
        type: MEDIA_UPLOAD_REQUESTED
    });

    const postConfig = {
        method: 'POST',
        body: data
    }

    return kitFileUpload(`${config.get('articleApi')}minstemme/resources/upload/`, postConfig)
        .then(response => {
            return response.json();
        })
        .then(data => {
            dispatch({
                type: MEDIA_UPLOAD_SUCCEEDED,
                data: {data}
            })
        })
        .catch(error => dispatch({
            type: MEDIA_UPLOAD_FAILED,
            data: error
        }));
};

export const deleteResource = resourceId => dispatch => {
    dispatch({
        type: RESOURCE_DELETE_REQUESTED
    });

    kitFetch(`${config.get('articleApi')}minstemme/resources/${resourceId}/`, { method: 'DELETE' })
        .then(res => dispatch({
            type: res['error-id'] ? RESOURCES_FAILED : RESOURCE_DELETED,
            data: res['error-id'] ? res.message : resourceId
        }))
        .catch(error => dispatch({
            type: RESOURCES_FAILED,
            data: error
        }));
};

export const uploadVideo = (uploadData, userId, progressHandler) => dispatch => {
    const file = uploadData[0];

    // DEBUG
    /* return dispatch({
        type: VIDEO_UPLOAD_SUCCEEDED,
        data: '012wY1cVJ5bg'
    }); */

    return orderUploadUrl(userId, file)
        .then(json => {
            const fd = new FormData();
            fd.append('file', file, file && file.name);
            fd.append('filename', file && file.name);
            fd.append('title', file && file.name);
            fd.append('mediaType', 'video/*');
            fd.append('identifier', json.multimediaId);

            fetch(json.url, { method: 'POST', body: fd })
                .then(() => {
                    console.log(`uploaded id ${json.multimediaId} to ${json.url}`);
                    dispatch({
                        type: VIDEO_UPLOAD_SUCCEEDED,
                        data: json.multimediaId
                    });
                });
        });
};

export const uploadVideoStatus = (userId, mmid) => dispatch => {
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({
            mmid: mmid,
            userId: userId
        })
    };

    let pending = false;

    const interval = setInterval(() => {
        if (!pending) {
            kitFetch(`${config.get('articleApi')}minstemme/resources/upload_status/`, postConfig)
                .then(json => {
                    console.log('Upload status: ', json.status);
                    pending = false;

                    if (['done', 'failed'].includes(json.status)) {
                        clearInterval(interval);
                    }

                    dispatch({
                        type: VIDEO_UPLOAD_STATUS,
                        data: json.status
                    });
                });
        }

        pending = true;
    }, 5000);
};

export const publishVideo = (userId, mmid) => dispatch => {
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({
            mmid: mmid,
            userId: userId
        })
    };

    dispatch({
        type: VIDEO_UPLOAD_STATUS,
        data: 'publishing'
    });

    kitFetch(`${config.get('articleApi')}minstemme/resources/publish_video/`, postConfig)
        .then(data => {
            console.log('Publish status: ', data.status);

            if (data && ['general', 'published'].includes(data.status)) {
                dispatch({
                    type: VIDEO_UPLOAD_STATUS,
                    data: 'published'
                });
                
                dispatch({
                    type: MEDIA_UPLOAD_SUCCEEDED,
                    data: {data}
                });
            } else {
                dispatch({
                    type: VIDEO_UPLOAD_FAILED
                });
            }
        });
};

export const videoDialogDismissed = () => dispatch => {
    dispatch({
        type: VIDEO_UPLOAD_STATUS,
        data: null
    });
};

const orderUploadUrl = (userId, file) => {
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({
            filename: file && file.name,
            userId: userId
        })
    };

    return kitFetch(`${config.get('articleApi')}minstemme/resources/order_upload_link/`, postConfig);
};
