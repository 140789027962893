import {
    CURRICULUM_BY_IDS_REQUESTED, CURRICULUM_BY_IDS_SUCCEEDED, CURRICULUM_BY_IDS_FAILED
} from 'admin/src/redux/actions/filter';

const initialState = {
    curriculumByIds: null,
    curriculum_fetching: null,
    curriculum_error: false,
    updated: null
};

const curriculumByIds = (state = initialState, action) => {
    switch (action.type) {
        case CURRICULUM_BY_IDS_REQUESTED:
            return {
                ...state,
                curriculum_fetching: true,
                curriculum_error: false
            };
        case CURRICULUM_BY_IDS_SUCCEEDED:
            return {
                ...state,
                curriculumByIds: action.data,
                curriculum_fetching: false,
                curriculum_error: false
            };
        case CURRICULUM_BY_IDS_FAILED:
            return {
                ...state,
                curriculumByIds: [],
                curriculum_fetching: false,
                curriculum_error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default curriculumByIds;
