import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { dmsUrl } from 'common/dms/dmsUrl';
import { ArticleHeader } from './tpl/ArticleHeader';
import { ArticleBody } from './tpl/ArticleBody';
import { Footer } from './footer/Footer';
import { RelatedResources } from './RelatedResources';
import { CurriculumView } from './CurriculumView';
import { NotFound } from '../404';
import { HeaderPortal } from './header/Header';
import bgImage from './header/headerBackground.jpeg';

let focusedBlock,
    scrolling = false;

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    article: {
        marginBottom: '.25em',
        paddingBottom: '2em',
        '& .article__content': {
            minHeight: spacing(4),
            lineHeight: '1.5em'
        },
        '& .article__bodytext': {
            '& p:first-child': {
                marginTop: 0,
            }
        },
        '& .btm-spacer': {
            marginBottom: '1em'
        },
        '& figure': {
            margin: 0
        }
    },
    spinner: {
        width: '100%',
        textAlign: 'center'
    }
}));


export const Main = ({ data, fetching, focusId, onPreviewClick, anchorObj }) => {
    const { content, subjects } = data,
        [focusedId, setFocusedId] = useState(),
        classes = useStyles();

    useEffect(() => {
        if (focusedId !== focusId) {
            const id = focusId;

            try {
                const idArr = id.split('_'),
                    idx = parseInt(idArr[idArr.findIndex(x => x === 'blocks') + 1]),
                    block = document.getElementById(`@section_${idArr[3]}.block_${idx}`);

                if (!block) {
                    return;
                }

                scrolling = true;
                focusedBlock = block;
            } catch (error) {
                // console.log(error);
            }

            setFocusedId(focusId);
        } else if (focusedBlock) {
            focusedBlock.scrollIntoView({ behavior: scrolling ? 'smooth' : 'auto' });
            scrolling = false;
            focusedBlock = null;
        }
    }, [focusedId, focusId]);

    const handlePreviewClick = e => {
        const { target } = e;
        let id = target.id,
            p = target;

        while (id.substr(0, 8) !== '@section' && p.parentElement) {
            p = p.parentElement;
            id = p.id;
        }

        id = id.split('.');

        if (id.length > 1) {
            onPreviewClick && onPreviewClick(id[0].replace('@section_', ''), id[1].replace('block_', ''));
        }
    };

    useEffect(() => {
        // hack to enable NRK video-embed
        if (document.getElementsByClassName('nrk-video').length)
        {
            const scr = document.createElement('script');
            scr.src = 'https://static.nrk.no/ludo/latest/video-embed.js';
            scr.id = 'nrkVideo';
            document.head.appendChild(scr);
            console.log('added NRK video script');
        }
    }, [content]);

    if (data && data.error) {
        return (
            <>
                <main className="content" onClick={handlePreviewClick}>
                    <article className={classes.article}>
                        <ArticleHeader block={{ title: 'Ups, her fant vi ingen stemmer!', resources: { mediaList: []} }} pageType="404" />
                        <NotFound />
                    </article>
                </main>
                <footer>
                    <Footer />
                </footer>
            </>
        );
    }

    const { sections, page_type, curriculum } = content,
        sbj = subjects && JSON.stringify(subjects.map(s => ({ id: s.name })));

    return (
        <>
            <Helmet>
                <title>{ sections[0].blocks[0] && sections[0].blocks[0].title } - Min stemme</title>
                <meta property="og:title" content={ sections[0].blocks[0] && sections[0].blocks[0].title } />
                <meta property="og:type" content="Article" />
                <meta property="og:url" content={ document.location.href } />
                <meta property="og:image" content={`${(content.cardImage && dmsUrl(content.cardImage)) || (sections[0].blocks[0].resources.mediaList[0] && dmsUrl(sections[0].blocks[0].resources.mediaList[0]))}?dimension=400x400`} />
                <meta name="description" content={ sections[0].blocks[0] && sections[0].blocks[0].leadtext } />
            </Helmet>
            <HeaderPortal
                backgroundImage={`linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)),url(${bgImage})`}
                height="96px"
                langIcon={{ fill: 'black' }}
                infoIcon={{ fill: 'black' }}
                color="black"
            >
                <img src="/minstemme-logo_svart.svg" alt="Min stemme logo" />
            </HeaderPortal>
            {fetching
                ? <div className={classes.spinner}><img src="/ms-spinner.gif" alt="Henter artikkel" /></div>
                : (<>
                    <main className="content" onClick={handlePreviewClick}>
                        <article className={classes.article}>
                            <ArticleHeader block={sections[0].blocks[0]} pageType={page_type} />
                            {(sbj || curriculum) && <CurriculumView data={sbj || curriculum} />}
                            {(sections[1].blocks && !!sections[1].blocks.length) &&
                                <ArticleBody blocks={sections[1].blocks} anchorObj={anchorObj} />
                            }
                        </article>
                    </main>
                    <footer>
                        <RelatedResources pageId={data.unique_id || `page_${data.id}`} section={sections[2]} />
                        <Footer />
                    </footer>
                </>)}
        </>
    );
};
