import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    icon: {
        maxWidth: '90%',
        width: 512,
        height: 512,
        margin: '0 auto',
        '& img': {
            width: '100%'
        }
    }
}));


export const NotFound = () => {
    const classes = useStyles();

    return (
        <div className={classes.icon}>
            <img src="/404.svg" alt="404" />
        </div>
    )
};
