import {
    LEVEL_REQUESTED, LEVEL_SUCCEEDED, LEVEL_FAILED, LEVEL_UPDATED
} from '../actions/filter';

const initialState = {
    level_data: [],
    level_fetching: null,
    level_error: false
};

const level = (state = initialState, action) => {
    switch (action.type) {
        case LEVEL_REQUESTED:
            return {
                ...state,
                level_fetching: true,
                level_error: false
            };
        case LEVEL_SUCCEEDED:
            return {
                level_data: action.data.resources,
                level_fetching: false,
                level_error: false,
                updated: Date.now()
            };
        case LEVEL_UPDATED:
            if (action.status === 'added') {
                return {
                    ...state,
                    level_data: state.level_data.concat([action.data]),
                    updated: Date.now()
                };
            } else if (action.status === 'deleted') {
                return {
                    ...state,
                    level_data: state.level_data.filter(c => {
                        if (c.id !== action.data.id) {
                            return c;
                        }

                        return false;
                    }),
                    updated: Date.now()
                }
            } else {
                return {
                    ...state,
                    level_data: state.level_data.map(s => {
                        return s.id === action.data.id ? action.data : s;
                    }),
                    updated: Date.now()
                }
            }
        case LEVEL_FAILED:
            return {
                ...state,
                level_data: [],
                level_fetching: false,
                level_error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default level;
