import React, {Fragment} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slider from 'react-slick';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
// import rehypeSanitize from 'rehype-sanitize';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import { Dm } from './Dm';
import { License } from './License';
import setImageStyle from 'admin/src/Article/imageFilters/filterStyle';
import { svgToSrc } from 'admin/src/Article/helpers/svgToSrc';
import { dmsUrl } from 'common/dms/dmsUrl';

import 'admin/src/Article/slick_styles/slick.css';
import 'admin/src/Article/slick_styles/slick-theme.css';
// import { ShareResources } from 'portal/src/content/shareResource';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
    articleBody: {
        paddingBottom: spacing(1),
        marginRight: 'auto',
        marginLeft: 'auto',
        fontSize: 18,
        /*fontSize: 20,*/
        lineHeight: 1.5,
        /*  maxWidth: 500,*/
        maxWidth: 600,
        [breakpoints.down('xs')]: {
            marginRight: 7,
            marginLeft: 7,
            paddingLeft: 7,
            paddingRight: 7,
            fontSize: 16,
        },
        '& a': {
            color: '#006B8D'
        },
        '& .article__bodytext': {
            '& .row': {
                '&.large': {
                    width: '100%'
                },
                '&.medium': {
                    width: '50%',
                },
                '&.small': {
                    width: '25%'
                },
                '&.row--img-left': {
                    float: 'left',
                    maxWidth: '50%',
                    paddingTop: spacing(1),
                    paddingRight: spacing(2),
                    paddingBottom: spacing(2)
                },
                '&.row--img-right': {
                    float: 'right',
                    maxWidth: '50%',
                    paddingTop: spacing(1),
                    paddingLeft: spacing(2),
                    paddingBottom: spacing(2)
                }
            },
            '& .iframe[Attributes Style]': {
                width: '500px',
                height: '250px',
                borderTopWidth: '0px',
                borderRightWidth: '0px',
                borderBottomWidth: '0px',
                borderLeftWidth: '0px',
            },
            '& blockquote': {
                margin: 0,
                '& > p: before': {
                    content: '"«"'
                },
                '& > p:after': {
                    content: '"»"'
                }
            },
            '& figure:not(.slick)': {
                margin: 0,
                padding: 0
            }
        },
        '& .license': {
            display: 'inline-block',
            '& > img': {
                width: 'auto'
            }
        },
        '& .media__caption, & .media__credit': {
            display: 'inline',
            paddingRight: '.3em',
            color: '#636363',
            lineHeight: 1.35
        },
        '& .media__caption': {
            fontSize: '.875em'
        },
        '& .media__credit': {
            fontSize: '.75em'
        },
        '& .section--auto': {
            margin: '1.5em inherit !important'
        },
        '& .row__grid': {
            '& .row--grid': {
                paddingTop: spacing(1),
                paddingRight: spacing(2),
                paddingBottom: spacing(1),
                boxSizing: 'border-box',
            },
            '&.article__bodytext:after': {
                content: '""',
                display: 'table',
                clear: 'both'
            },
            '&.article__media': {
                display: 'flex',
                flexFlow: 'wrap',
                '& video': {
                    width: '100%'
                },
                '& .download-link': {
                    flex: '1 0 100%',
                    '& svg': {
                        margin: '0px 6px -6px 0'
                    }
                }
            },
            '& .large': {
                width: '100%'
            },
            '& .medium': {
                width: '50%',
            },
            '& .small': {
                width: '25%'
            }
        },
        '& img': {
            width: '100%'
        },
        '& .dm': {
            '& h2': {
                width: '704px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },
        '.anchorMode & [data-unique-key]': {
            '&:hover': {
                background: 'yellow'
            },
            '&.active': {
                background: 'green'
            },
            '& *': {
                pointerEvents: 'none'
            }
        },
        '& .shareResources': {
            marginBottom: 80
        }
    },
    slideshow: {
        '& .slick-prev': {
            'z-index': 1
        }
    },
    sliderItem: {
        paddingBottom: '56.25%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: '#808080'
    },
    facts: {
        backgroundColor: '#06c1ed',
        padding: '7px',
        color: 'white'
    },
}));


export const ArticleBody = ({ blocks, anchorObj }) => {
    const classes = useStyles();

    const renderBodyText = block => {
        const { resources } = block;
        return (
            <div className={`row__grid ${block.type}`}>
                {
                    (block.type === 'article__bodytext' && resources.mediaList && resources.mediaList.length)
                        ? renderMediaItem(block, resources.mediaList[0], 0)
                        : null
                }
                <div className="article__bodytext">
                    {
                        (!!block.title && !block.titleHidden) &&
                        <h2><Markdown children={block.title} /></h2>
                    }
                    {
                        block.type === 'article__facts'
                            ? (
                                <div className={classes.facts}>
                                    <Markdown children={block.bodytext} rehypePlugins={[rehypeRaw]} />
                                </div>
                            )
                            : (
                                <Markdown children={block.bodytext} rehypePlugins={[rehypeRaw]} />
                            )
                    }
                </div>
            </div>
        );
    };

    const renderMedia = block => {
        const { settings } = block;

        if (!block.resources || !block.resources.mediaList || !block.resources.mediaList.length) {
            return null;
        }

        const renderSlideShow = (item, index) => {
            const url = dmsUrl(item);

            return (
                <Fragment key={index}>
                    <figure
                        className={`slick ${classes.sliderItem}`}
                        // focuspoint
                        // data-focus="false"
                        // data-focus-x="{{ item.media.focuspoint.x }}"
                        // data-focus-y="{{ item.media.focuspoint.y }}"
                        // data-focus-w="{{ item.media.focuspoint.imgattr.width }}"
                        // data-focus-h="{{ item.media.focuspoint.imgattr.height }}"
                        // id="block-{{ block.unique_key }}--media--{{ $index }}-media"
                        style={{
                            ...setImageStyle(item.media),
                            backgroundImage: `url(${(item.mimetype && item.mimetype.indexOf('png') > -1) ? url + '?mediaType=image/png' : url})`
                        }}
                    >
                    </figure>
                    <div className="module__content">
                        {(item.local && item.local.description && item.local.description !== '') &&
                            <span className="media__caption">{item.local.description}</span>}
                        {(item.local && item.local.credit) && <span className="media__credit">Foto: {item.local.credit}</span>}
                        {(item.licenses && item.licenses[0]) && <License code={item.licenses[0]} />}
                    </div>
                </Fragment>
            );
        };

        if (settings.layout === 'slideshow') {
            const conf = {
                infinite: false,
                variableWidth: false,
                adaptiveHeight: false,
                arrows: true,
                initialSlide: 0,
                pauseOnHover: true,
                pauseOnFocus: false
            };

            return (
                <div className={classes.slideshow}>
                    <Slider {...conf}>
                        {block.resources.mediaList.map((m, index) => renderSlideShow(m, index))}
                    </Slider>
                </div>
            )
        }

        return (
            <div className={`row__grid ${block.type}`}>
                {block.resources.mediaList.map((item, index) => renderMediaItem(block, item, index))}
            </div>
        );
    };

    const renderMediaItem = (block, item, index) => {
        const desc = (item.local && item.local.description) || item.description,
            cred = item.local && item.local.credit,
            license = item.licenses && item.licenses[0],
            pos = (block.settings && block.settings.layout) || '',
            size = (item.settings && item.settings.size) || '';

        const renderItem = () => {
            if (item.type === 'video') {
                return (
                    <video controls>
                        <source src={item.url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                );
            }

            return (
                <figure className="article__bodytext">{
                    item.mimetype === 'image/svg+xml' && item.source
                        ? <img className="svg" src={svgToSrc(item.source)} alt={desc} style={setImageStyle(item.media)} />
                        : <img src={dmsUrl(item)} alt={desc} style={setImageStyle(item.media)} />
                }
                </figure>
            );
        };

        if (item.type === 'document') {
            return (
                <a key={index} className="download-link" href={`${item.url}?filename=${item.filename}`}>
                    <DownloadIcon />
                    <span>{item.filename}</span>
                </a>
            );
        }

        return (
            <div key={index} className={`row row--${pos} ${size}${((desc && desc !== '') || (cred && cred !== '') ? ' btm-spacer' : '')}`}>
                {renderItem()}
                {desc && desc !== '' && <span className="media__caption">{desc}</span>}
                {cred && cred !== '' && <small className="media__credit">Foto: {cred}</small>}
                {license && <License code={license} />}
            </div>
        );
    };

    const renderBlock = (block, index) => {
        const id = `@section_1.block_${index}`;

        if (block.settings.visibility !== 'visible') {
            return null;
        }

        if (block.type === 'article__bodytext') {
            return (
                <section
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`article__bodytext-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`article__content${anchorObj.value === block._unique_key ? ' active' : ''}`}
                >
                    {renderBodyText(block)}
                </section>
            );
        } else if (block.type === 'article__facts') {
            return (
                <section
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`article__facts-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`article__content${anchorObj.value === block._unique_key ? ' active' : ''}`}
                >
                    {renderBodyText(block)}
                </section>
            );
        } else if (block.type === 'article__media') {
            return (
                <div
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`article__media-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`${anchorObj.value === block._unique_key ? 'active' : ''}`}
                >
                    {
                        ((block.title && !block.titleHidden) || block.bodytext) &&
                        <section className="article__content">
                            {renderBodyText(block)}
                        </section>
                    }
                    <section className="article__content section--auto">
                        {renderMedia(block)}
                    </section>
                </div>
            );
        } else if (block.type === 'digitaltMuseum') {
            return (
                <div
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`digitaltMuseum-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`${anchorObj.value === block._unique_key ? 'active' : ''}`}
                >
                    {
                        ((block.title && !block.titleHidden) || block.bodytext) &&
                        <section className="article__content">
                            {renderBodyText(block)}
                        </section>
                    }
                    <section className="section--auto dm">
                        <Dm block={block} />
                    </section>
                </div>
            );
        }

        return null;
    };

    return (
        <div id="articleBody" className={classes.articleBody}>
            {blocks.map((b, index) => renderBlock(b, index))}
            {/* <div className="shareResources"><ShareResources /></div> */}
        </div>
    );
};
