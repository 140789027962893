import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { requestCurriculums } from 'admin/src/redux/actions/filter';

const useStyles = makeStyles(() => ({
    curriculum: {
        maxWidth: 600,
        margin: '0 auto',
        paddingBottom: '1em',
        '& h4': {
            margin: 0
        },
        '& h5:first-of-type': {
            marginTop: '1em'
        },
        '& h5, & p': {
            margin: '0 0 .5em'
        }
    },
    header: {
        cursor: 'pointer'
    }
}));

export const CurriculumView = ({ data }) => {
    const /* setSubjects = () => {
        try {
            const s = JSON.parse(data);
            
            if (Array.isArray(s)) {
                let res = [];
                s.forEach(sbj => {
                    res = res.concat(sbj.children.map(c => c.id));
                })

                return res;
            } else {
                return [s];
            }
        } catch (err) {
            return data.split(',');
        }},
        subjects = setSubjects(), */
        dispatch = useDispatch(),
        [showGoals, setShowGoals] = useState(false),
        { curriculumByIds } = useSelector(state => state.curriculumByIds),
        classes = useStyles();

    useEffect(() => {
        if (data) {
            const c = JSON.parse(data),
                ids = c.map(x => x.id);
            dispatch(requestCurriculums(ids));
        }
    }, [dispatch, data]);

    return (curriculumByIds && curriculumByIds.length)
        ? (
            <div className={`article__content ${classes.curriculum}`}>
                <h4 onClick={() => setShowGoals(!showGoals)} className={classes.header}>{`${showGoals ? 'Skjul' : 'Vis'} kompetansemål`}</h4>
                {showGoals && curriculumByIds.map(s => (
                    <Fragment key={s.id}>
                        <h5>{s.title}</h5>
                        {s.children.map(l => (
                            <Fragment key={l.id}>
                                <p>{l.title}</p>
                                <ul>
                                {l.children.map(g => (
                                    <li key={g.id}>{g.description}</li>
                                ))}
                                </ul>
                            </Fragment>
                        ))}
                    </Fragment>
                ))}
            </div>
        )
        : null

    /* const groupeGoals = () => {
        let res = {};

        subjects.forEach(s => {
            s.children && s.children.forEach(g => {
                if (!res[g.subjectTitle]) {
                    res[g.subjectTitle] = {}
                }

                if (!res[g.subjectTitle][g.levelTitle]) {
                    res[g.subjectTitle][g.levelTitle] = []
                }

                res[g.subjectTitle][g.levelTitle].push(g.title);
            });
        });

        return res;
    };

    const groupedGoals = subjects && groupeGoals(); */

    /* return groupedGoals
        ? (
            <div className={`article__content ${classes.curriculum}`}>
                <h4 onClick={() => setShowGoals(!showGoals)} className={classes.header}>{`${showGoals ? 'Skjul' : 'Vis'} kompetansemål`}</h4>
                {showGoals && Object.entries(groupedGoals).map(([sk, sv], i) => (
                    <Fragment key={i}>
                        <h5>{sk}</h5>
                        {Object.entries(sv).map(([lk, lv], j) => (
                            <Fragment key={j}>
                                <p>{lk}</p>
                                <ul>
                                {lv.map((g, k) => (
                                    <li key={k}>{g}</li>
                                ))}
                                </ul>
                            </Fragment>
                        ))}
                    </Fragment>
                ))}
            </div>
        )
        : null */
};
