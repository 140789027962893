import {
    ARTICLES_REQUESTED, ARTICLES_SUCCEEDED, ARTICLES_FAILED, ARTICLE_DELETED
} from '../actions/article';

import { ARTICLES_APPENDED } from 'portal/src/redux/articleAction';


const initialState = {
    owner: null,
    articles: [],
    countFound: 0,
    countReturned: 0,
    fetching: null,
    error: false
}

const articleList = (state = initialState, action) => {
    switch (action.type) {
        case ARTICLES_REQUESTED:
            return {
                ...state,
                articles: [],
                fetching: true,
                error: false
            };
        case ARTICLES_SUCCEEDED:
            return {
                articles: action.data.articles,
                countFound: action.data.total,
                countReturned: Math.min(action.data.limit, action.data.articles.length),
                fetching: false,
                error: false
            };
        case ARTICLES_APPENDED:
            return {
                articles: state.articles.concat(action.data.articles),
                countFound: action.data.total,
                countReturned: state.articles.length + action.data.articles.length,
                fetching: false,
                error: false
            };
        case ARTICLE_DELETED:
            const a = state.articles.filter(x => x.id !== action.data);
            return {
                ...state,
                articles: a,
                countFound: state.countFound - 1,
                countReturned: a.length
            };
        case ARTICLES_FAILED:
            return {
                ...state,
                articles: null,
                fetching: false,
                owner: action.owner,
                error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default articleList;
