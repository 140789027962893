import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from '@material-ui/core';

import { requestDmSearch } from 'admin/src/redux/actions/media';
import { ProgressIndicator } from 'admin/src/common/ProgressIndicator';

import { dmObjMapper } from 'admin/src/Article/mediaLib/tpl/dmObj';
import setImageStyle from 'admin/src/Article/imageFilters/filterStyle';
import { buildQuery } from 'admin/src/Article/helpers/buildQuery';
import { dmsUrl } from 'common/dms/dmsUrl';


const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
    grid: {
        maxWidth: '704px',
        margin: '1.5em auto',
        display: 'flex',
        flexWrap: 'wrap',
        '&.grid-wide': {
            maxWidth: '1064px'
        }
    },
    dm: {
        marginLeft: spacing(-1),
        padding: spacing(1),
        fontSize: '14px',
        '& a': {
            color: '#3a2e2d',
            textDecoration: 'none'
        },
        '& .row': {
            position: 'relative',
            boxShadow: '0 1.5px 2px rgba(0, 0, 0, 0.06), 0 1.5px 1.5px rgba(0, 0, 0, 0.06)',
            backgroundColor: '#f2f2f2',
            '&.small': {
                width: '164px',
                height: '246px',
            },
            '&.medium': {
                width: '336px',
                height: '246px',
            },
            '&.large': {
                width: '688px',
                height: '492px',
            },
            '& figure': {
                height: '66.66%',
                display: 'flex',
                '& img': {
                    margin: 'auto',
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '90%',
                    maxHeight: '90%'
                }
            },
            '& .media__caption': {
                maxHeight: '3.9em',
                display: 'block',
                overflow: 'hidden',
                padding: '0 0.625em 1em'
            },
            '& .media__credit': {
                position: 'absolute',
                left: spacing(1),
                bottom: spacing(1),
                fontSize: '12px'
            }
        }
    },
    showMore: {
        marginBottom: '2em',
        textAlign: 'center',
        '& > a': {
            display: 'inline-block',
            border: '2px solid black',
            borderRadius: '2px',
            fontSize: '18px',
            fontWeight: 'bold',
            lineHeight: 1,
            padding: '.75em 1.5em',
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
                color: 'white',
                backgroundColor: 'black'
            }
        }
    }
}));

let timer, numListItems;

export const Dm = ({block}) => {
    const dispatch = useDispatch(),
        classes = useStyles(),
        [executedSearches, setExecutedSearches] = useState(),
        [showMax, setShowMax] = useState(false),
        dmSearch = useSelector(state => state.previewDmSearch),
        { resources, maxListItems, showMoreLabel, labelHidden, settings, searchUrl } = block,
        layout = (settings && settings.layout) || '';

    useEffect(() => {
        if (!dmSearch) {
            return;
        }

        Object.entries(dmSearch).forEach(([key, value]) => {
            const { fetching, error, data } = value;
            
            if (fetching || error) {
                setExecutedSearches({ ...dmSearch, [key]: null });
            } else if (!fetching && !error && data) {
                const res = {
                    objects: data.objects.map(x => dmObjMapper(x))
                };

                setExecutedSearches({ ...dmSearch, [key]: res });
            }
        });
    }, [dmSearch]);

    const renderCard = item => {
        const size = (item.settings && item.settings.size) || '',
            desc = item.title,
            count = item.image_count,
            url = dmsUrl(item);

        return (
            <div key={item.image_id} className={classes.dm}>
                <a href={`https://digitaltmuseum.org/${item.dimuId}`} target="_blank" rel="noreferrer noopener">
                    <div className={`row ${size}`}>
                        <figure>
                            {url && <img src={`${url}?dimension=400x400`} alt={desc} style={{...setImageStyle(item.media)}} />}
                        </figure>
                        <div className="content">
                            {(desc && desc !== '') && <span className="media__caption">{desc}</span>}
                            {typeof count !== 'undefined' && <span className="media__credit">{count} bilder</span>}
                        </div>
                    </div>
                </a>
            </div>
        );
    };

    const renderLoadingCard = (size, idx) => {
        return (
            <div key={idx} className={classes.dm}>
                <div className={`row ${size}`}>
                    <ProgressIndicator />
                </div>
            </div>
        );
    };

    const renderSearch = entry => {
        const { filter, query, settings } = entry,
            f = { ...filter, rows: (showMax ? maxListItems : (filter && filter.rows)) || 4 };

        if (!f || !f.rows) {
            return null;
        }

        const q = buildQuery(query, f);

        if (!executedSearches || !executedSearches.hasOwnProperty(q)) {
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                setExecutedSearches({ ...executedSearches, [q]: null });
                console.log('setExecutedSearch: ', q);
                dispatch(requestDmSearch(q, true, true));
            }, 2500);
        }

        if (!executedSearches || !executedSearches[q] || !executedSearches[q].objects) {
            if (executedSearches && executedSearches[q] === null) {
                return null;
            }

            let s = (settings && settings.size) || 'small';

            numListItems += f.rows;

            if (numListItems > maxListItems) {
                f.rows = (f.rows - (numListItems - maxListItems));
            }

            const cards = Array(f.rows).fill(s);

            return cards.map((s, idx) => renderLoadingCard(s, idx));
        }

        const objects = executedSearches[q].objects.map(o => ({ ...o, settings: { size: settings.size || 'small' } }));
        numListItems += objects.length;

        if (numListItems > maxListItems) {
            return objects.slice(0, (objects.length - (numListItems - maxListItems))).map(renderCard);
        }

        return objects.map(renderCard);
    };

    const renderEntry = entry => {
        if (numListItems >= maxListItems) {
            return null;
        }

        if (entry.type === 'search') {
            return renderSearch(entry);
        }

        numListItems++;
        return renderCard(entry);
    };

    const renderSearchMoreButton = () => {
        const showMore = e => {
            e.target.setAttribute('style', 'display:none');
            setShowMax(true);
        };

        return (
            <div className={classes.showMore}>
                {searchUrl
                    ? <Link href={searchUrl} target="_blank">{showMoreLabel}</Link>
                    : <Link onClick={e => showMore(e)}>{showMoreLabel}</Link>
                }
            </div>
        );
    };

    if (resources.entryList && resources.entryList.length) {
        numListItems = 0;

        return (
            <>
                <div className={`${classes.grid} ${layout}`}>
                    {resources.entryList.map(renderEntry)}
                </div>
                {!!!labelHidden && renderSearchMoreButton()}
            </>
        )
    }

    return null;
};
