import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
// import rehypeSanitize from 'rehype-sanitize';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { IconButton } from '@material-ui/core';

import { License } from './License';
import setImageStyle from 'admin/src/Article/imageFilters/filterStyle';
import { dmsUrl } from 'common/dms/dmsUrl';

// import { getImageDimensions } from '../../helpers/getImageDimensions';


const HEADER_IMAGE_MAX_WIDTH = 960,
    HEADER_IMAGE_HEIGHT = 500;

const useStyles = makeStyles(({spacing, palette, breakpoints}) => ({
    articleHead: {
        paddingBottom: '1em',
        '& .backLink': {
            marginTop: 33,
            marginLeft: 43,
            display: 'flex',
            alignItems: 'center',
            '& a': {
                textDecoration: 'none',
                color: '#000000DE'
            },
            '& .arrowBackIosIcon': {
                width: 14,
                height: 14,
                color: '#000000DE'
            },
            [breakpoints.down('xs')]: {
                marginTop: 3,
                marginLeft: 3,
            }
        },
        '.anchorMode &': {
            cursor: 'not-allowed',
            opacity: .5
        },
        '& .wrapperTitleAuthor': {
            margin: 0,
            '& .article__title': {
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: 720,
                textAlign: 'center',
                /* lineHeight: '1.25em',*/
                fontWeight: 'bold',
                fontSize: '48px',
                // marginTop: 67,
                marginBottom: 40,
                '& h1': {
                    margin: 0,
                    fontSize: '1.25em'
                },
                [breakpoints.down('xs')]: {
                    fontSize: 18,
                    marginLeft: 7,
                    marginRight: 7,
                    textAlign: 'center',
                    marginTop: 10,
                    marginBottom: 10,
                },
            },
            '& .article__byline': {
                marginBottom: 27,
                fontSize: 14,
                textAlign: 'center',
                '& p': {
                    margin: 0
                },
                '& a': {
                    textDecoration: 'none',
                    color: '#000000'
                },
            }
        },
        '& .article__cover': {
            backgroundColor: '#DBE0E1',
            color: '#000',
            '& figure.media--cover': {
                maxWidth: `${HEADER_IMAGE_MAX_WIDTH}px`,
                margin: '0 auto',
                textAlign: 'center',
                '& img': {
                    maxWidth: '100%',
                    verticalAlign: 'middle'
                }
            },
            '& .article__content': {
                position: 'relative',
                height: '35%',
                '& .titleWrapper': {
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    left: 0,
                    bottom: 'auto',
                    margin: '0 auto',
                    transform: 'translateY(-50%)',
                    zIndex: 1
                },
                '& .media__credit': {
                    position: 'absolute',
                    bottom: 0,
                    padding: '.75em',
                    opacity: .5,
                    zIndex: 1
                },
            },
        },
        '& .article__content': {
            maxWidth: 600,
            margin: '0 auto',
            '& .article__leadtext': {
                paddingTop: '1.5em',
                [breakpoints.down('xs')]: {
                    marginLeft: '7px',
                    marginRight: '7px',
                    paddingLeft: '7px',
                    paddingRight: '7px'
                }
            }
        },
        '& .media_credit': {
            maxWidth: `${HEADER_IMAGE_MAX_WIDTH}px`,
            margin: '0 auto',
            fontSize: 16,
            paddingTop: '.3em',
            textAlign: 'center',
            '& .media__caption, & .media__credit': {
                fontSize: '.875em',
                fontWeight: 'normal',
                color: '#636363'
            },
            '& .media__credit': {
                fontSize: '.75em'
            },
            '& > .license': {
                marginLeft: '.5em',
                verticalAlign: 'middle'
            },
            [breakpoints.down('xs')]: {
                fontSize: 14,
                paddingLeft: 7
            }
        }
    }
}));


export const ArticleHeader = ({ block, pageType, children }) => {
    const classes = useStyles(),
        // [imgDimensions, setImgDimensions] = useState(),
        // [imgTopPos, setImgTopPos] = useState(0),
        { resources } = block,
        { mediaList, backgroundImage } = resources,
        bgImg = mediaList || backgroundImage,
        bgImgUrl = bgImg.length && dmsUrl(bgImg[0])/*,
        [fp, setFp] = useState(bgImg && bgImg.length && bgImg[0].media.focuspoint)*/;

    /*useEffect(() => {
        if (bgImg && bgImg.length && imgDimensions) {
            if (bgImg[0].media && bgImg[0].media.focuspoint) {
                const containerCenter = Math.floor(HEADER_IMAGE_HEIGHT / 2),
                    scale = HEADER_IMAGE_MAX_WIDTH / imgDimensions.width,
                    height = scale * imgDimensions.height,
                    focusFactor = (parseFloat(bgImg[0].media.focuspoint.y) + 1) / 2, //Focus point of resize image in px
                    focus = height - Math.floor(focusFactor * height),
                    remainder = height - focus, //Reduce offset if necessary so image remains filled
                    containerRemainder = HEADER_IMAGE_HEIGHT - containerCenter;

                let focusOffset = focus - containerCenter; //Calculate difference between focus point and center

                if (remainder < containerRemainder) focusOffset -= containerRemainder - remainder;
                if (focusOffset < 0) focusOffset = 0;

                setImgTopPos(focusOffset * -100 / HEADER_IMAGE_HEIGHT + '%');
            }
        }
    }, [imgDimensions, imgTopPos, bgImg, fp]);*/

    /* const onLoadedData = e => {
        setImgDimensions({ width: e.target.width, height: e.target.height });
    }; */

    /* if (bgImg && bgImg.length && fp && bgImg[0].media.focuspoint && bgImg[0].media.focuspoint.y !== fp.y) {
        setFp(bgImg[0].media.focuspoint);
    } */

    return (
        <header id="@section_0.block_0" className={`${classes.articleHead} ${pageType}`}>
            <div className="backLink">
                <IconButton href="/"><ArrowBackIosIcon className="arrowBackIosIcon" /></IconButton>
                <Link to="/">Forside</Link>
            </div>
            <div className="wrapperTitleAuthor">
                <div className="article__title">
                    <h1>{block.title}</h1>
                </div>
                {block.author &&
                    <div className="article__byline">
                        <a href={block.authorUrl} target="_blank" rel="noreferrer">Av {block.author}</a>
                        {/*<p>Av {block.author}</p>*/}
                    </div>
                }
            </div>

            {
                !!bgImg.length && (
                    <>
                        <section className={`article__cover ${block.settings.color}`}>
                            <figure className="article__media  media--cover media--image focuspoint"
                                id="article__cover-media"
                                style={{
                                    ...setImageStyle(bgImg[0].media),
                                    // backgroundImage: `url('${bgImgUrl}?dimension=1200x1200')`
                                }}
                            >
                                <img src={`${bgImgUrl}?dimension=1200x1200`} alt={(bgImg[0].local && bgImg[0].local.description) || bgImg[0].filename} />
                            </figure>
                        </section>
                    </>
                )
            }

            {mediaList && mediaList[0] && (
                <div className="media_credit">
                    {mediaList[0].local && mediaList[0].local.description !== '' &&
                        <span className="media__caption">{mediaList[0].local.description}</span>}
                    {mediaList[0].local && mediaList[0].local.credit &&
                        <span className="media__credit">{mediaList[0].local.credit}</span>}
                    {mediaList[0].licenses && mediaList[0].licenses[0] && <License code={mediaList[0].licenses[0]} />}
                </div>
            )}
            <section className="article__content">
                <div className="article__leadtext">
                    <strong><Markdown children={block.leadtext} rehypePlugins={[rehypeRaw]} /></strong>
                </div>
            </section>
        </header>
    );
};
