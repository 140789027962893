import {
    SUBJECT_REQUESTED, SUBJECT_SUCCEEDED, SUBJECT_FAILED, SUBJECT_UPDATED
} from '../actions/filter';

const initialState = {
    subject_data: [],
    subject_fetching: null,
    subject_error: false
};

const subject = (state = initialState, action) => {
    switch (action.type) {
        case SUBJECT_REQUESTED:
            return {
                ...state,
                subject_fetching: true,
                subject_error: false
            };
        case SUBJECT_SUCCEEDED:
            return {
                subject_data: action.data.resources,
                subject_fetching: false,
                subject_error: false,
                updated: Date.now()
            };
        case SUBJECT_UPDATED:
            if (action.status === 'added') {
                return {
                    ...state,
                    subject_data: state.subject_data.concat([action.data]),
                    updated: Date.now()
                };
            } else if (action.status === 'deleted') {
                return {
                    ...state,
                    subject_data: state.subject_data.filter(c => {
                        if (c.id !== action.data.id) {
                            return c;
                        }

                        return false;
                    }),
                    updated: Date.now()
                }
            } else {
                return {
                    ...state,
                    subject_data: state.subject_data.map(s => {
                        return s.id === action.data.id ? action.data : s;
                    }),
                    updated: Date.now()
                }
            }
        case SUBJECT_FAILED:
            return {
                ...state,
                subject_data: [],
                subject_fetching: false,
                subject_error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default subject;
