import React, { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SearchFilters } from 'common/search/SearchFilters';
import { FilterChipRow } from 'common/search/FilterChipRow';

const useStyles = makeStyles((theme) => ({
    autoCompleteWrapper: {
        display: 'flex',
        maxWidth: 558,
        margin: '24px auto',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: 0,
            '& > *': {
                margin: theme.spacing(1),
                width: 251,
            },
        }
    },
    wrapper: {
        width: 644,
        height: 237,
        [theme.breakpoints.down('sm')]: {
            width: 327,
            height: 334,
            minWidth: 300
        },
        '@media (min-width: 280px) and (max-width: 320px)' : {
            width: 300,
            height: 334,
            minWidth: 280
        },
        background: '#006C85',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.11), 0px 3px 1px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: 32,
        position: 'absolute',
        left: '50%',
        top: '74px',
        transform: 'translate(-50%, 0)'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: 500
    },
    searchInput: {
        width: 558,
        height: 54,
        background: '#025568',
        borderRadius: 8,
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            width: 250,
        },
    },
    title: {
        textAlign: 'center',
        color: 'white',
        fontSize: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            marginBlockStart: 15,
        },
        fontWeight: 700,
        paddingTop: 24,
        marginBlockStart: 7,
        marginBlockEnd: 7,
    },
    search: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1)
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: '#006C85',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#006C85',
                borderWidth: '0.5px',
            }
        }
    },
    chipWrapper: {
        marginTop: theme.spacing(8)
    }
}));

export const ArticleFilter = ({ onChange, form }) => {
    const matches = useMediaQuery('(max-width:320px)'),
        classes = useStyles(matches),
        [searchTerm, setSearchTerm] = useState(form.searchTerm);

    const handleDeleteFilter = id => {
        onChange({ ...form, filters: form.filters.filter(f => f.id !== id) });
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter' && searchTerm) {
            onChange && onChange({ ...form, searchTerm: searchTerm })
        }
    };

    const handleClearSearch = (e) => {
        setSearchTerm('');
        onChange && onChange({ ...form, searchTerm: '' })
    };

    const handleFilterChange = filters => {
        onChange && onChange({ ...form, filters: form.filters.concat(filters) });
    };

    return (
        <div>
            <div className={classes.wrapper}>
                <p className={classes.title}>Demokrati og deltakelse for</p>
                <div className={classes.autoCompleteWrapper}>
                    <SearchFilters
                        onChange={handleFilterChange}
                        selectedFilters={form.filters}
                    />
                </div>
                <div className={classes.search}>
                    <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Søk blant innholdet"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={handleKeyDown}
                        value={searchTerm}
                        InputProps={{
                            className: classes.searchInput,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: form.searchTerm && (
                                <IconButton
                                    style={{ color: 'white' }}
                                    onClick={handleClearSearch}
                                >
                                    <CancelIcon />
                                </IconButton>
                            )
                        }}
                    />
                </div>
            </div>
            <div className={classes.chipWrapper}>
                <FilterChipRow filters={form.filters || []} handleDeleteFilter={handleDeleteFilter} />
            </div>
        </div>
    );
};
