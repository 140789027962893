import React, { useEffect } from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { createMuiTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
// import ReactGA from 'react-ga';

import { HeaderPortal } from "./content/header/Header";
import { Footer } from "./content/footer/Footer";
import { ArticleCardList } from "./content/ArticleCardList";
import bgImage from "./content/header/headerBackground.jpeg";

//#006C85 --primary color
//#E9EFF2 --secondary color
const useStyles = makeStyles(theme => ({
    /*root: {
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            /!*background: '#E9EFF2',*!/
            /!* position: 'relative'*!/
        },
    },*/
    content: {
        position: "relative",
        background: "#E9EFF2",
        maxWidth: 1920,
        /* maxWidth: 1440,*/
        minHeight: "100vh",
        margin: "0 auto",
    },
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#006C85",
        },
        secondary: {
            main: "#E9EFF2",
        },
        action: {
            main: "#0083A8",
        },
    },
    typography: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
    },
});

export const MinstemmmePortal = () => {
    const classes = useStyles();

    /* useEffect(() => {
        if (document.location.hostname !== 'localhost') {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, []); */

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>Min stemme</title>
                <meta
                    name="description"
                    content="Min stemme er et nettsted som skal fremme demokrati og deltagelse i barnehage og skole.
                Nettstedet tilbyr oppgaver og undervisningsopplegg innenfor en rekke temaer. Eksempler på temaer er demokrati,
                menneskerettigheter, nasjonale minoriteter."
                />
            </Helmet>
            <div className={classes.content}>
                <header>
                    <HeaderPortal
                        backgroundImage={`linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bgImage})`}
                        height="268px"
                        langIcon={{ fill: "white" }}
                        infoIcon={{ fill: "white" }}
                        color="white"
                    >
                        <img
                            src="/minstemme-logo_kvit.svg"
                            alt="Min stemme logo"
                        />
                    </HeaderPortal>
                </header>
                <main>
                    <ArticleCardList />
                </main>
                <footer>
                    <Footer />
                </footer>
            </div>
        </ThemeProvider>
    );
};
