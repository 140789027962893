const commaSeparateIds = form => {
    // return form.filters.join(',');
    return form.filters.map(f => f.id);
};

const prefix = params => {
    return params === '' ? '?' : '&';
}

export const setParams = (form) => {
    if (!form) {
        return '';
    }

    let params = '';
    
    if (form.orderBy) {
        params = `${params}${prefix(params)}order_by=${form.orderBy.toLowerCase()}`;
    }

    if (form.radioButtonValue) {
        params = `${params}${prefix(params)}page_type=${form.radioButtonValue}`;
    }

    if (form.filters.length > 0) {
        params = `${params}${prefix(params)}filter=${commaSeparateIds(form)}`;
    }

    if (form.searchTerm) {
        params = `${params}${prefix(params)}q=${form.searchTerm}`;
    }

    if (form.limit) {
        params = `${params}${prefix(params)}limit=${form.limit}`;
    }

    if (form.offset) {
        params = `${params}${prefix(params)}offset=${form.offset}`;
    }

    if (form.status) {
        params = `${params}${prefix(params)}status=${form.status}`;
    }

    return params;
};
