const config = {
    api: '/minstemme-api/v1/',
    articleApi: '/minstemme-api/v1/',
    museumApi: '/museum-api/museums/',
    placeApi: 'https://place-api.dimu.org/api/search',
    portalUrl: process.env.REACT_APP_PORTAL_URL,
    publicApi: `${process.env.REACT_APP_API_GATEWAY}/minstemme-api/public/v1/`
};

export default config;
