import {
    ARTICLE_REQUESTED, ARTICLE_SUCCEEDED,
    ARTICLE_UPDATE_REQUESTED, ARTICLE_UPDATED,
    ARTICLE_FAILED
} from '../actions/article';

const initialState = {
    data: null,
    created: false,
    fetching: null,
    error: false,
    saved: false
};

const article = (state = initialState, action) => {
    switch (action.type) {
        case ARTICLE_REQUESTED:
            return {
                ...state,
                created: false,
                fetching: true,
                error: false
            };
        case ARTICLE_SUCCEEDED:
            return {
                ...state,
                data: action.data,
                created: !!action.created,
                fetching: false,
                error: false
            };
        case ARTICLE_UPDATE_REQUESTED:
            return {
                ...state,
                fetching: true,
                saved: false
            };
        case ARTICLE_UPDATED:
            return {
                ...state,
                data: action.data,
                fetching: false,
                saved: true
            };
        case ARTICLE_FAILED:
            return {
                ...state,
                data: { id: action.pageId },
                created: false,
                fetching: false,
                error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default article;
