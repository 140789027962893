import { RELATED_ARTICLES_REQUESTED, RELATED_ARTICLES_SUCCEEDED, RELATED_ARTICLES_FAILED } from './articleAction';


const initialState = {
    relatedArticles: null,
    relatedAssignments: null,
    relatedContributions: null,
    fetching: null,
    error: false
}

const relatedArticleList = (state = initialState, action) => {
    switch (action.type) {
        case RELATED_ARTICLES_REQUESTED:
            return {
                relatedArticles: null,
                relatedAssignments: null,
                relatedContributions: null,
                fetching: true,
                error: false
            };
        case RELATED_ARTICLES_SUCCEEDED:
            const art = action.data.filter(x => x.type === 'article'),
                asm = action.data.filter(x => x.type === 'assignment'),
                ctb = action.data.filter(x => x.type === 'contribution');

            return {
                relatedArticles: art.length ? art : null,
                relatedAssignments: asm.length ? asm : null,
                relatedContributions: ctb.length ? ctb : null,
                fetching: false,
                error: false
            };
        case RELATED_ARTICLES_FAILED:
            return {
                relatedArticles: null,
                relatedAssignments: null,
                relatedContributions: null,
                fetching: false,
                error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default relatedArticleList;
