import {
    CATEGORY_REQUESTED, CATEGORY_SUCCEEDED, CATEGORY_UPDATED, CATEGORY_FAILED
} from '../actions/filter';

const initialState = {
    category_data: [],
    category_fetching: null,
    category_error: false,
    updated: null
};

const category = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORY_REQUESTED:
            return {
                ...state,
                category_fetching: true,
                category_error: false
            };
        case CATEGORY_SUCCEEDED:
            return {
                category_data: action.data.resources.sort((a, b) => a.children.length - b.children.length),
                category_fetching: false,
                category_error: false
            };
        case CATEGORY_UPDATED:
            const upd = {
                id: action.data.id,
                title: action.data.title,
                type: 'category',
                source: parseInt(action.data.source || -1),
                children: []
            };

            if (action.status === 'added') {
                return {
                    ...state,
                    category_data: upd.source ? updateParent(state.category_data, 'add', upd) : state.category_data.concat([upd]),
                    updated: Date.now()
                };
            } else if (action.status === 'deleted') {
                const item = state.category_data.find(x => x.id === upd.id);

                return {
                    ...state,
                    category_data: item
                        ? state.category_data.filter(c => {
                            if (c.id !== upd.id) {
                                return c;
                            }

                            return false;
                        })
                        : state.category_data.map(c => {
                            c.children = c.children.filter(x => {
                                if (x.id !== upd.id) {
                                    return x;
                                }
            
                                return false;
                            });

                            return c;
                        }),
                    updated: Date.now()
                }
            } else {
                return {
                    ...state,
                    category_data: upd.source
                        ? updateParent(state.category_data, 'update', upd)
                        : state.category_data.map(s => {
                            return s.id === upd.id ? upd : s;
                        }),
                    updated: Date.now()
                }
            }
        case CATEGORY_FAILED:
            return {
                ...state,
                category_data: [],
                category_fetching: false,
                category_error: true
            };
        default:
            return {
                ...state
            }
    }
};

const updateParent = (data, action, item) => {
    const p = data.find(x => x.id === item.source);

    if (!p) {
        console.warn(`No parent category with id ${item.source}`);
        return data;
    }

    let upd = [...data];

    return upd.map(c => {
        if (c.id === p.id) {
            if (action === 'add') {
                c.children = c.children.concat([item]);
            } else {
                c.children = c.children.map(x => {
                    return x.id === item.id ? item : x;
                });
            }
        }

        return c;
    });
};

export default category;
