import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
    leadText: {
        display: 'block',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        lineHeight: '1.8em'
    },
    card: {
        maxWidth: 343,
        height: 433,
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.04), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: 16,
        cursor: 'pointer'
    },
    cardBtn: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'

    },
    cardMedia: {
        height: 216,
        '&.default': {
            padding: '1em',
            boxSizing: 'border-box',
            objectFit: 'contain'
        }
    },
    cardContent: {
        maxHeight: 216,
        minHeight: 216,
        boxSizing: 'border-box',
        '& > h5, & > p': {
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': 2
        },
        '& > p': {
            '-webkit-line-clamp': 4
        }
    }
});

export const ArticleCard = ({ article, onSelect }) => {
    let cardImg = article.image_url + '?dimension=400x400';
    const classes = useStyles();

    if (cardImg && cardImg.indexOf('png') > -1) {
        cardImg += '&mediaType=image/png';
    }

    const handleSelect = () => {
        onSelect && onSelect(`/${article.dimu_code}/${article.slug}`);
    };

    return (
        <Card className={classes.card} onClick={() => handleSelect()}>
            <CardActionArea className={classes.cardBtn}>
                <CardMedia
                    component="img"
                    className={`${classes.cardMedia}${cardImg ? '' : ' default'}`}
                    alt="Article Image"
                    image={cardImg ? cardImg : '/minstemme-logo_stabla.svg'}
                    title="Article Image"
                />
                <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h5">
                        {article.title}
                    </Typography>
                    <Typography component="p" className={classes.leadText}>
                        <b>{{ article: 'Ressurs', assignment: 'Oppgaver', contribution: 'Bidrag' }[article.subtype]}</b> {article.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
